<template>
  <d-edit-card
    :key="$route.params.id"
    :id="$route.params.id"
    :fetch-function="refillMethodController.getRefillMethodById"
    :entity.sync="form"
    prev-page="RefillMethodsList"
    :card-title="`Refill Method ${isEditingForm ? 'updating' : 'creation'}`"
    remove-confirm-title="Are you sure you want to delete this refill method?"
    remove-success-title="Refill method successfully removed"
    :remove-function="loadRefillMethod"
    :submit-function="submitForm"
    redirect-after-creation="RefillMethodsEdit"
  >
    <template #default="{ data }">
      <q-card-section class="col">
        <div class="row">
          <div
            class="col-xs-12 col-md-6 q-col-gutter-md"
            :class="$q.screen.lt.md ? 'q-mb-sm' : 'q-pr-md'"
          >
            <q-input
              v-model.trim="data.name"
              outlined
              label="Name"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-input
              v-model.trim="data.code"
              outlined
              label="Code"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-select
              v-model="data.currency"
              :options="currencyDictionary"
              :rules="[rules.notNullField]"
              outlined
              clearable
              label="Currency"
              hint="Required field*"
              option-value="id"
              option-label="name"
            />
            <d-user-select
              v-model="data.users"
              use-input
              :clear-value="[]"
              :options="usersDictionary"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-select
              outlined
              v-model="data.merchants"
              :options="merchantsDictionary"
              option-value="id"
              option-label="name"
              label="Merchants"
              multiple
              hint="Required field*"
              clearable
              :rules="[rules.notNullField]"
            />

            <div
              :style="{
                minHeight: '56px',
              }"
              class="flex items-center"
            >
              <q-circular-progress
                v-if="systemGroupFormDictionary.loading"
                indeterminate
                rounded
                size="sm"
                color="primary"
                class="q-mx-sm"
              />

              <q-checkbox
                v-if="!systemGroupFormDictionary.loading"
                v-model="data.syncRequisite"
              />

              <span class="text-subtitle1"> Sync Requisite </span>
            </div>

            <q-select
              v-if="data.syncRequisite"
              outlined
              v-model="greenbackMethod"
              :options="systemGroupFormDictionaryOptions"
              :loading="systemGroupFormDictionary.loading"
              :input-value.sync="systemGroupFormDictionary.search"
              option-value="value"
              option-label="label"
              label="Greenback Method"
              hint="Required field*"
              clearable
              use-input
              :rules="[rules.notNullField]"
              @input-value="systemGroupFormDictionary.search = $event"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <d-fees-table :items.sync="data.userFee" :agents="data.users" />
            <div style="margin-top: 20px">
              <d-refill-method-payload-table :payload.sync="data.payload" />
            </div>
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ loading, remove }">
      <d-edit-form-actions
        :is-editing-form="isEditingForm"
        prev-page="RefillMethodsList"
        @remove="remove"
      />
    </template>
  </d-edit-card>
</template>

<script>
import { DEditFormActions } from '@/features/edit-form-actions';
import { DFeesTable } from '@/features/fees-table';
import { DRefillMethodPayloadTable } from '@/features/refill-method-payload-table';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import {
  refillMethodController as apiController,
  currencyController,
} from '@/shared/api';
import { ROLES } from '@/shared/constants';
import helperMixin from '@/shared/mixins/refillAndPayoutMethods';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import { authUtils } from '@/shared/utils';
import { uid } from 'quasar';

export default {
  components: {
    DEditCard,
    DUserSelect,
    DEditFormActions,
    DFeesTable,
    DRefillMethodPayloadTable,
  },
  mixins: [
    helperMixin(
      (el) =>
        el.roles.includes(ROLES.ROLE_AGENT) ||
        el.roles.includes(ROLES.ROLE_SUPERVISOR)
    ),
    withIsEditingForm(),
  ],
  data: () => ({
    form: {
      name: null,
      code: null,
      currency: null,
      greenbackMethod: null,
      users: [],
      merchants: [],
      userFee: [],
      payload: [],
      syncRequisite: false,
    },
    refillMethodController: apiController(),
    rules: {
      notNullField: (val) => {
        return val?.toString().length > 0 || 'Please type value';
      },
    },
    currencyDictionary: [],
    systemGroupFormDictionary: {
      list: [],
      loading: false,
      search: '',
    },
  }),
  watch: {
    'form.syncRequisite': {
      async handler(enabled) {
        if (!enabled) {
          return;
        }

        this.systemGroupFormDictionary.loading = true;

        try {
          const { data } =
            await this.refillMethodController.systemGroupFormRefillMethod();

          this.systemGroupFormDictionary.list = data.map((item) =>
            Object.freeze({
              ...item,
              label: `${item.systemGroupName} (ID: ${item.integrationId})`,
              value: `${item.systemGroupName}-${item.integrationId}`,
            })
          );
        } finally {
          this.systemGroupFormDictionary.loading = false;
        }
      },
      immediate: true,
    },
  },
  computed: {
    greenbackMethod: {
      get() {
        if (
          !this.form.syncRequisite ||
          !this.form.greenbackMethod?.systemGroupName ||
          !this.form.greenbackMethod?.integrationId
        ) {
          return null;
        }

        const { integrationId, systemGroupName } = this.form.greenbackMethod;

        return (
          this.systemGroupFormDictionary.list.find(
            (item) =>
              item.systemGroupName === systemGroupName &&
              item.integrationId === integrationId
          ) || null
        );
      },
      set(greenbackMethodItem) {
        this.form.greenbackMethod = greenbackMethodItem
          ? {
              integrationId: greenbackMethodItem.integrationId,
              systemGroupName: greenbackMethodItem.systemGroupName,
            }
          : null;
      },
    },
    systemGroupFormDictionaryOptions() {
      const searchText = this.systemGroupFormDictionary.search
        .trim()
        .toLowerCase();

      if (!searchText) {
        return this.systemGroupFormDictionary.list;
      }

      return this.systemGroupFormDictionary.list.filter(({ label }) =>
        label.toLowerCase().includes(searchText)
      );
    },
  },
  methods: {
    async loadRefillMethod(id) {
      const { data } = await this.refillMethodController.getRefillMethodById(
        id
      );

      return {
        data: {
          ...data,
          payload:
            data?.payload.map((el) => ({
              id: uid(),
              ...el,
            })) || [],
        },
      };
    },

    async submitForm({
      name,
      code,
      id,
      users,
      merchants,
      userFee,
      currency,
      syncRequisite,
      payload,
    }) {
      const commonEntityRequest = async () => {
        const action = this.isEditingForm
          ? this.refillMethodController.updateRefillMethod
          : this.refillMethodController.createRefillMethod;

        const { data } = await action({
          id,
          name,
          code,
          syncRequisite,
          greenbackMethod: this.greenbackMethod?.systemGroupName
            ? {
                systemGroupName: this.greenbackMethod.systemGroupName,
                integrationId: this.greenbackMethod.integrationId,
              }
            : [],
          currencyId: currency?.id,
          userFee: this.isEditingForm
            ? userFee
            : // eslint-disable-next-line no-unused-vars
              userFee.map(({ id, ...props }) => props),
          // eslint-disable-next-line no-unused-vars
          payload: payload?.map(({ id, ...other }) => other),
        });

        return data;
      };

      const refill = await commonEntityRequest();
      await Promise.all([
        this.addItems({
          sourceEntity: refill,
          items: users,
          key: 'users',
          removeFn: this.refillMethodController.removeUserAtRefillMethodType,
          addFn: this.refillMethodController.addUserToRefillMethodType,
        }),
        this.addItems({
          sourceEntity: refill,
          items: merchants,
          key: 'merchants',
          removeFn:
            this.refillMethodController.removeMerchantToRefillMethodType,
          addFn: this.refillMethodController.addMerchantToRefillMethodType,
        }),
      ]);
      return {
        data: { id: refill.id },
      };
    },
  },
  async mounted() {
    const currencyResponse = await currencyController(
      authUtils.getRoleByHierarchy()
    ).getCurrency();

    this.currencyDictionary = Object.freeze(currencyResponse.data);
  },
};
</script>
