var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-edit-card',{key:_vm.$route.params.id,attrs:{"id":_vm.$route.params.id,"fetch-function":_vm.refillMethodController.getRefillMethodById,"entity":_vm.form,"prev-page":"RefillMethodsList","card-title":("Refill Method " + (_vm.isEditingForm ? 'updating' : 'creation')),"remove-confirm-title":"Are you sure you want to delete this refill method?","remove-success-title":"Refill method successfully removed","remove-function":_vm.loadRefillMethod,"submit-function":_vm.submitForm,"redirect-after-creation":"RefillMethodsEdit"},on:{"update:entity":function($event){_vm.form=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('q-card-section',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-6 q-col-gutter-md",class:_vm.$q.screen.lt.md ? 'q-mb-sm' : 'q-pr-md'},[_c('q-input',{attrs:{"outlined":"","label":"Name","hint":"Required field*","rules":[_vm.rules.notNullField]},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.name"}}),_c('q-input',{attrs:{"outlined":"","label":"Code","hint":"Required field*","rules":[_vm.rules.notNullField]},model:{value:(data.code),callback:function ($$v) {_vm.$set(data, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.code"}}),_c('q-select',{attrs:{"options":_vm.currencyDictionary,"rules":[_vm.rules.notNullField],"outlined":"","clearable":"","label":"Currency","hint":"Required field*","option-value":"id","option-label":"name"},model:{value:(data.currency),callback:function ($$v) {_vm.$set(data, "currency", $$v)},expression:"data.currency"}}),_c('d-user-select',{attrs:{"use-input":"","clear-value":[],"options":_vm.usersDictionary,"hint":"Required field*","rules":[_vm.rules.notNullField]},model:{value:(data.users),callback:function ($$v) {_vm.$set(data, "users", $$v)},expression:"data.users"}}),_c('q-select',{attrs:{"outlined":"","options":_vm.merchantsDictionary,"option-value":"id","option-label":"name","label":"Merchants","multiple":"","hint":"Required field*","clearable":"","rules":[_vm.rules.notNullField]},model:{value:(data.merchants),callback:function ($$v) {_vm.$set(data, "merchants", $$v)},expression:"data.merchants"}}),_c('div',{staticClass:"flex items-center",style:({
              minHeight: '56px',
            })},[(_vm.systemGroupFormDictionary.loading)?_c('q-circular-progress',{staticClass:"q-mx-sm",attrs:{"indeterminate":"","rounded":"","size":"sm","color":"primary"}}):_vm._e(),(!_vm.systemGroupFormDictionary.loading)?_c('q-checkbox',{model:{value:(data.syncRequisite),callback:function ($$v) {_vm.$set(data, "syncRequisite", $$v)},expression:"data.syncRequisite"}}):_vm._e(),_c('span',{staticClass:"text-subtitle1"},[_vm._v(" Sync Requisite ")])],1),(data.syncRequisite)?_c('q-select',{attrs:{"outlined":"","options":_vm.systemGroupFormDictionaryOptions,"loading":_vm.systemGroupFormDictionary.loading,"input-value":_vm.systemGroupFormDictionary.search,"option-value":"value","option-label":"label","label":"Greenback Method","hint":"Required field*","clearable":"","use-input":"","rules":[_vm.rules.notNullField]},on:{"update:inputValue":function($event){return _vm.$set(_vm.systemGroupFormDictionary, "search", $event)},"update:input-value":function($event){return _vm.$set(_vm.systemGroupFormDictionary, "search", $event)},"input-value":function($event){_vm.systemGroupFormDictionary.search = $event}},model:{value:(_vm.greenbackMethod),callback:function ($$v) {_vm.greenbackMethod=$$v},expression:"greenbackMethod"}}):_vm._e()],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('d-fees-table',{attrs:{"items":data.userFee,"agents":data.users},on:{"update:items":function($event){return _vm.$set(data, "userFee", $event)}}}),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('d-refill-method-payload-table',{attrs:{"payload":data.payload},on:{"update:payload":function($event){return _vm.$set(data, "payload", $event)}}})],1)],1)])])]}},{key:"actions",fn:function(ref){
            var loading = ref.loading;
            var remove = ref.remove;
return [_c('d-edit-form-actions',{attrs:{"is-editing-form":_vm.isEditingForm,"prev-page":"RefillMethodsList"},on:{"remove":remove}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }